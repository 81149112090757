import clsx from "clsx";
import { PropsWithChildren } from "react";
import { twc } from "react-twc";
import RestakeTabContext from "../../context/RestakeTabContext";
import { useNavigate } from "react-router-dom";
import TokenInputContext from "../../context/TokenInputContext";
import useQuery from "../../hooks/useQuery";
import { useTranslation } from "react-i18next";
import { extractToTokenNameByName } from "../../utils/regex";

const { useRestakeTabTracked } = RestakeTabContext;
const { useTokenInputTracked } = TokenInputContext;
function FormTagSelector() {
  const [restakeTab] = useRestakeTabTracked();
  const navigate = useNavigate();
  const [, setTokenInputTracked] = useTokenInputTracked();
  const query = useQuery();
  const { t } = useTranslation();
  return (
    <FormTagSelectorStyle>
      <Tag
        selected={restakeTab === "deposit"}
        onClick={() => {
          const newQuery = new URLSearchParams();
          const symbol = query.get("symbol");
          const chain = query.get("chain");
          if (symbol) {
            newQuery.set("symbol", symbol);
          } else if (chain) {
            newQuery.set("chain", chain);
          }
          navigate(`/?${newQuery.toString()}`);
          setTokenInputTracked("");
        }}
      >
        {t("restake.deposit")}
      </Tag>
      <Tag
        selected={restakeTab === "unstake"}
        onClick={() => {
          const newQuery = new URLSearchParams();
          const symbol = query.get("symbol");
          const chain = query.get("chain");
          if (symbol) {
            newQuery.set("symbol", extractToTokenNameByName(symbol));
          } else if (chain) {
            newQuery.set("chain", chain);
          }
          navigate(`/?${newQuery.toString()}&tab=unstake`);
          setTokenInputTracked("");
        }}
      >
        {t("restake.unstake")}
      </Tag>
    </FormTagSelectorStyle>
  );
}

const Tag = ({
  children,
  selected,
  onClick,
}: PropsWithChildren & {
  selected: boolean;
  onClick: () => void;
}) => {
  return (
    <TagStyle className={clsx(selected && "bg-[#1F2636]")} onClick={onClick}>
      {children}
    </TagStyle>
  );
};
const FormTagSelectorStyle = twc.div`
  grid grid-cols-2 p-1 gap-1 place-items-center
  border border-white border-opacity-10
  rounded-xl cursor-pointer
`;

const TagStyle = twc.button`
  rounded-xl w-full
  h-12 grid place-content-center
  duration-300
  hover:bg-[#1F2636]
  text-sm font-semibold
`;

export default FormTagSelector;
